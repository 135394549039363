
import Home from "./views/home";
import About from "./views/about";
// import Contact from "./views/contact";
import NavBar from "./components/navBar";
import Footer from "./components/footer";
import BetaTool from "./views/betatool";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div>
      <NavBar/>
      <BrowserRouter>
        {/* <GlobalStyle/> */}
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about/" element={<About />}></Route>
          <Route path="/betatool/" element={<BetaTool />}></Route>
          {/* <Route path="/contact/" element={<Contact />}></Route> */}
        </Routes>
      </BrowserRouter>
      <Footer/>
    </div>
  )
}

export default App