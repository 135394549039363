import logo from '../images/navBar/logo.png';
import { useState } from 'react';

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <nav className="bg-white fixed top-0 left-0 right-0 z-50 w-full">
        <div className="border-b-[1px] border-dark-green max-w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16 w-full">
            <div className="flex flex-col">
              <img className="h-16 py-2" src={logo} alt="Logo" />
              {/* <a href="/" className="text-black">
                Logo
              </a> */}
            </div>
            <div className="hidden md:block">
              <div className="ml-4 flex items-center gap-4">
                <a href="/" className="text-dark-green hover:bg-dark-green hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  Home
                </a>
                <a href="/about/" className="text-dark-green hover:bg-dark-green hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  About
                </a>
                {/* <a href="/" className="text-dark-green hover:bg-dark-green hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  Contact
                </a> */}
                <a href="/betaTool" className="text-dark-green hover:bg-dark-green hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  BetaTool
                </a>
                <a href="https://app.sproutfull.nl/" className="text-dark-green bg-light-green hover:bg-dark-green hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  Launch app
                </a>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button onClick={toggleNavbar} className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                ) : (
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                )}
              </button> 
            </div>
          </div>
        </div>

        <div className={`${isOpen ? "block" : "hidden"} md:hidden`}>
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a href="https://app.sproutfull.nl" className="text-dark-green bg-light-green hover:bg-dark-green hover:text-white block px-3 py-2 rounded-md text-base font-medium">
              Launch app
            </a>
            <a href="/" className="text-dark-green hover:bg-dark-green hover:text-white block px-3 py-2 rounded-md text-base font-medium">
              Home
            </a>
            <a href="/about/" className="text-dark-green hover:bg-dark-green hover:text-white block px-3 py-2 rounded-md text-base font-medium">
              About
            </a>
            {/* <a href="/" className="text-dark-green hover:bg-dark-green hover:text-white block px-3 py-2 rounded-md text-base font-medium">
              Contact
            </a> */}
            <a href="/betatool/" className="text-dark-green hover:bg-dark-green hover:text-white block px-3 py-2 rounded-md text-base font-medium">
              BetaTool
            </a>
          </div>
        </div>
      </nav>
      <div className="w-full h-16"/>
    </div>
  )
}

export default NavBar;